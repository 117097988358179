import { FC, HTMLAttributes } from 'react';

import ReactGA from 'react-ga4';

const REACT_APP_GA_ID: string = window.location.host === 'console.growing3.ai' ? 'G-R1ECG7R2YY' : 'G-NFN6VBNXBP';

// Google Analytics
ReactGA.initialize(REACT_APP_GA_ID);

export const TrackingProvider: FC<HTMLAttributes<HTMLElement>> = (props) => {
  return <>{props.children}</>;
};
