import { Button, FormControl, FormHelperText, InputLabel, Link, OutlinedInput, Stack, Typography } from '@mui/material';
import { FC, useCallback, useContext, useState } from 'react';
import { getHomepageUrl, validateEmail } from 'common/utils';
import { useAccount, useConnect } from 'wagmi';

import ClientAPI from 'common/ClientAPI';
import { GoogleIcon } from 'components/icons/GoogleIcon';
import { InjectedConnector } from '@wagmi/core';
import { TrackingContext } from 'contexts/TrackingContext';
import { Uris } from 'Uris';
import classes from './LoginPage.module.scss';
import { getWeb3IconByConnector } from 'components/web3/iconMapping';
import { useNavigate } from 'react-router-dom';
import { useTracking } from 'common/hooks/useTracking';
import { useWeb3Login } from 'components/web3/useWeb3Login';

export const LoginPage: FC = () => {
  const navigate = useNavigate();
  const { track } = useTracking();
  const { entryPoint$ } = useContext(TrackingContext);
  const [email, setEmail] = useState<string>('');
  const [chain, setChain] = useState<string>('');

  const [emailError, setEmailError] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<string>('');

  const checkEmailExists = useCallback(
    async (email: string) => {
      const emailError = !validateEmail(email);
      setEmailError(emailError);
      if (emailError) return;

      await ClientAPI.checkEmailExists(email)
        .then(({ message }) => {
          if (message === 'exists') {
            // navigate to sign in/up page and pass email data via state
            navigate(Uris.Pages.Auth.SignIn, {
              state: { email },
            });
          } else if (message === 'not exists') {
            // navigate to sign in/up page and pass email data via state
            navigate(Uris.Pages.Auth.SignUp, {
              state: { email },
            });
          }
        })
        .catch((error) => {
          setBackendError(error instanceof Error ? error.message : 'Unknow Error');
        });
    },
    [navigate],
  );

  // web3 login related
  const onWeb3LoginSuccess = useCallback(
    (status?: string, redirect_url?: string, provider?: string) => {
      if (status === 'redirect' && redirect_url) {
        navigate(redirect_url, { state: { authentication_method: provider } });
        return;
      }

      // has redirect url -> directly change
      if (redirect_url) {
        window.location.replace(redirect_url);
        return;
      }
      // redirect to homepage
      window.location.replace(getHomepageUrl(Uris.External.Home));
    },
    [navigate],
  );

  const { signInWithAddress } = useWeb3Login(onWeb3LoginSuccess);

  const { address, isConnected } = useAccount();
  const { connect, connectors } = useConnect({
    connector: new InjectedConnector(),
    onSuccess(data) {
      if (!data.connector?.name) return;
      const chain = data.connector.chains.find((chain) => chain.id === data.chain.id);
      if (!chain?.name) return;
      // this is the source of chain name, need to convert it into lower case
      const chainName = chain.name.toLowerCase();
      setChain(chainName);
      signInWithAddress(data.connector?.name, chainName, data.account);
    },
  });

  const signInWithWeb3 = useCallback(
    (provider: string) => {
      track('click', {
        sub_event: 'sso_attempted',
        custom_props: {
          entry_point: entryPoint$.getValue(),
          authentication_method: provider,
        },
      });
      if (!isConnected) connect();
      if (!address) return;
      if (!chain) return;
      signInWithAddress(provider, chain, address);
    },
    [isConnected, chain, address, connect, signInWithAddress, track, entryPoint$],
  );

  // oauth login related
  const signInWithOauth = useCallback(
    async (provider: string) => {
      track('click', {
        sub_event: 'sso_attempted',
        custom_props: {
          entry_point: entryPoint$.getValue(),
          authentication_method: provider,
        },
      });
      await ClientAPI.oauthSignIn(provider)
        .then(({ data: oauth_url }) => {
          if (!oauth_url) return;
          window.location.href = oauth_url;
        })
        .catch((error) => {
          setBackendError(error instanceof Error ? error.message : 'Unknow Error');
        });
    },
    [track, entryPoint$],
  );

  return (
    <Stack direction='row' className={classes.root}>
      <Stack className={classes.imgContainer}>
        <img alt='login' src={Uris.Public.Image.Auth.Login} className={classes.img} />
      </Stack>
      <Stack className={classes.block}>
        <Stack className={classes.container} spacing={3}>
          <Stack direction='row' justifyContent='center'>
            <img alt='logo' className={classes.logo} src={Uris.Public.Logo.NoSub.Dark} />
          </Stack>
          <Stack spacing={2}>
            <Button
              size='large'
              startIcon={<GoogleIcon />}
              className={classes.btn}
              variant='outlined'
              onClick={() => signInWithOauth?.('google')}
            >
              <Typography variant='subtitle1'>Continue with Google</Typography>
            </Button>

            {connectors.map((connector) => {
              return (
                <Button
                  size='large'
                  key={connector.id}
                  startIcon={getWeb3IconByConnector(connector.name)}
                  className={classes.btn}
                  variant='outlined'
                  onClick={() => signInWithWeb3?.(connector.name)}
                >
                  <Typography variant='subtitle1'>Continue with {connector.name}</Typography>
                </Button>
              );
            })}
          </Stack>
          <Typography>or</Typography>
          <Stack spacing={2}>
            <FormControl>
              <InputLabel error={!!emailError}>Your email address</InputLabel>
              <OutlinedInput
                size='medium'
                label={'Your email address'}
                error={!!emailError}
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
              {emailError ? (
                <Stack style={{ maxWidth: '360px' }}>
                  <FormHelperText error>Invalid email format</FormHelperText>
                </Stack>
              ) : null}
            </FormControl>
          </Stack>
          {backendError ? <Typography color='error'>{backendError}</Typography> : null}
          <Button className={classes.emailBtn} variant='contained' onClick={() => checkEmailExists(email)}>
            Continue with email
          </Button>
          <Typography variant='body2'>
            By clicking “Continue” you agree to our{' '}
            <Link variant='body2' href={getHomepageUrl(Uris.External.TermsOfService)} target='_blank'>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link variant='body2' href={getHomepageUrl(Uris.External.PrivacyPolicy)} target='_blank'>
              Privacy Policy
            </Link>
            .
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
