import {
  colorError40,
  colorError80,
  colorNeutral40,
  colorNeutral80,
  colorNeutralVariant40,
  colorNeutralVariant80,
  colorPrimary40,
  colorPrimary80,
  colorSecondary40,
  colorSecondary80,
  colorTertiary40,
  colorTertiary80,
} from 'common/params';

import { PaletteOptions } from '@mui/material';

export const lightPalett: PaletteOptions = {
  mode: 'light',
  primary: {
    main: colorPrimary40,
  },
  secondary: {
    main: colorSecondary40,
  },
  teritary: {
    main: colorTertiary40,
  },
  error: {
    main: colorError40,
  },
  neutral: {
    main: colorNeutral40,
  },
  neutralVariant: {
    main: colorNeutralVariant40,
  },
};
export const darkPalett: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: colorPrimary80,
    contrastText: '#381E72',
  },
  secondary: {
    main: colorSecondary80,
  },
  teritary: {
    main: colorTertiary80,
  },
  error: {
    main: colorError80,
  },
  neutral: {
    main: colorNeutral80,
  },
  neutralVariant: {
    main: colorNeutralVariant80,
  },
};
